import { CampusService } from "./v2/campus.service";
import { CatalogService } from "./v2/catalog.service";
import { EnrollmentService } from "./v2/enrollments.service";
import { FileService } from "./v2/file.service";
import { FinancesService } from "./v2/finances.service";
import { InvoiceService } from "./v2/invoice.service";
import { PaymentService } from "./v2/payment.service";
import { PaymentMethodsService } from "./v2/paymentMethod.service";
import { RecipientAccountsService } from "./v2/recipientAccount.service";

class HttpManager {
    private static instance: HttpManager;
    public catalogService: CatalogService;
    public financesService: FinancesService;
    public invoiceService: InvoiceService;
    public fileService: FileService;
    public paymentService: PaymentService;
    public campusService: CampusService;
    public paymentMethodService: PaymentMethodsService;
    public recipientAccountService: RecipientAccountsService;
    public enrollmentService: EnrollmentService;

    constructor() {
        this.catalogService = new CatalogService();
        this.financesService = new FinancesService();
        this.invoiceService = new InvoiceService();
        this.fileService = new FileService();
        this.paymentService = new PaymentService();
        this.campusService = new CampusService();
        this.paymentMethodService = new PaymentMethodsService();
        this.recipientAccountService = new RecipientAccountsService();
        this.enrollmentService = new EnrollmentService();
    }

    public static getInstance(): HttpManager {
        if (!HttpManager.instance) {
            HttpManager.instance = new HttpManager();
        }
        return HttpManager.instance;
    }
}

export default HttpManager;