import {useState, useRef, useReducer, useEffect, useCallback}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useDispatch, useSelector}
	from "react-redux"
import Multiselect
	from "multiselect-react-dropdown"
import {Popover}
	from "react-tiny-popover"
import _
	from "lodash"
import _debounce
	from "lodash/debounce"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,
	enableIdleLockSwitch,
	disableIdleLockSwitch,
	displayNotice,
	dismissNotice
}
	from "./../../globals"
import SectionContainer, {UserPrompt}
	from "./../../components/sectionContainer"
import CustomIcon
	from "./../../components/customIcon"
import
{
	UIState,
	baseRole,
	pageGrouping,
	weekDays,
	currencySuffix,
	getAccessPermissions,
	isEmpty
}
	from "./../../common"
import
{
	articleStatus,
	assignmentStatus,
	employeeStatus,
	courseStatus,
	courseLevelStatus,
	scheduleStatus
}
	from "./../../constants"
import SimpleDataService
	from "./../../services/simpleData.service"
import CatalogService
	from "./../../services/catalog.service"
import CoursesService
	from "./../../services/campus/courses.service"
import CourseLevelsService
	from "./../../services/campus/courseLevels.service"
import StudentsService
	from "./../../services/campus/students.service"
import AssignmentsService
	from "./../../services/assignments.service"
import SchedulesService
	from "./../../services/campus/schedules.service"
import EmployeesService
	from "./../../services/campus/employees.service"
import BuildingsService
	from "./../../services/campus/buildings.service"
import PeriodsService
	from "./../../services/campus/periods.service"
import StoreService
	from "./../../services/campus/store.service"
import LevelPrompt
	from "./levelPrompt"
import SchedulePrompt
	from "./schedulePrompt"
import EnrollmentPrompt
	from "./enrollmentPrompt"
import {styles}
	from "./style"

import "./../../components/dropdown/style.css"
import "./style.css"

type State                 = {isButtonDisabled : boolean}
const initialState : State = {isButtonDisabled : true}

type Action=
{
	type    : "setIsButtonDisabled",
	payload : boolean
}

const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled":
			return {...state, isButtonDisabled : action.payload}
		break;
	}
}

const Levels = ()=>
{
	const CATEGORIES        = 1
	const LEVEL_STATUSES    = 2
	const SCHEDULE_STATUSES = 3
	const MENTORS           = 4
	const BUILDINGS         = 5
	const PERIODS           = 6
	const COURSES           = 7
	const ENDING            = 8

	const [state, trigger]                                        = useReducer(reducer, initialState)
	const dispatch                                                = useDispatch()
	const idleLocked   : boolean                                  = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                            = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle  : boolean                                  = useSelector((state : RootState) => state.themeToggle.value)
	const currentUserCampus : any                                 = useSelector((state : RootState) => state.currentUserCampus.value);
	const campusRef         : any                                 = useSelector((state : RootState) => state.campusRef.value)
	const currentUser       : any                                 = useSelector((state : RootState) => state.userProfile.value)
	const basicInfo    : any                                      = useSelector((state : RootState) => state.basicInfo.value)
	const [userCampus, setUserCampus]                             = useState<string | null>(currentUserCampus)
	const noCampusesProvided : boolean                            = currentUserCampus == null
	const [courseRef, setCourseRef]                               = useState<any>({})
	const [levelStatusRef, setLevelStatusRef]                     = useState<any>({})
	const [buildingRef, setBuildingRef]                           = useState<any>({})
	const [categoryRef, setCategoryRef]                           = useState<any>({})
	const [classroomCount, setClassroomCount]                     = useState<number>(0)
	const [initSetup, setInitSetup]                               = useState<number | null>(null)
	const [studentsCount, setStudentsCount]                       = useState<number>(0)
	const [totalRecords, setTotalRecords]                         = useState<number>(0)
	const [levelCount, setLevelCount]                             = useState<number>(0)
	const [data, setData]                                         = useState<any[]>([])
	const [levelStatuses, setLevelStatuses]                       = useState<any[]>([])
	const [scheduleStatuses, setScheduleStatuses]                 = useState<any[]>([])
	const [periods, setPeriods]                                   = useState<any[]>([])
	const [mentors, setMentors]                                   = useState<any[]>([])
	const [buildings, setBuildings]                               = useState<any[]>([])
	const [categories, setCategories]                             = useState<any[]>([])
	const [courses, setCourses]                                   = useState<any[]>([])
	const [mentorsList, setMentorsList]                           = useState<any>([])
	const [mentorRefList, setMentorRefList]                       = useState<any>([])
	const [mentorPopoverOpen, setMentorPopoverOpen]               = useState<boolean>(false)
	const [mentorListDisplay, setMentorListDisplay]               = useState<boolean>(false)
	const [mentorSearchInProgress, setMentorSearchInProgress]     = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]                       = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested]         = useState<boolean>(false)
	const [extendedFilterShown, setExtendedFilterShown]           = useState<boolean>(false)
	const [searchError, setSearchError]                           = useState<string | null>(null)
	const [UIStatus, setUIStatus]                                 = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]                   = useState<number>(0)
	const [initMentors, setInitMentors]                           = useState<any>([])
	const [initKits, setInitKits]                                 = useState<any>([])
	const [initAssignments, setInitAssignments]                   = useState<any>([])
	const [warningReport, listWarnings]                           = useState<any>({})
	const [selectedCourse, setSelectedCourse]                     = useState<any>(null)
	const [selectedUser, setSelectedUser]                         = useState<any>(null)
	const [editableCourse, setEditableCourse]                     = useState<any>(null)
	const [selectedLevel, setSelectedLevel]                       = useState<any>(null)
	const [editableLevel, setEditableLevel]                       = useState<any>(null)
	const [selectedSchedule, setSelectedSchedule]                 = useState<any>(null)
	const [selectedEnrollment, setSelectedEnrollment]             = useState<any>(null)
	const [defaultNewLevel, setDefaultNewLevel]                   = useState<any>(null)
	const [defaultNewSchedule, setDefaultNewSchedule]             = useState<any>(null)
	const [pagination, setPagination]                             = useState<any>(null)
	const [firstUsableBuildingIndex, setFirstUsableBuildingIndex] = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                           = useState<boolean>(false)
	const [recordsSelection, setRecordsSelection]                 = useState<any>([])
	const count        : number[]                                 = [-1, 1]
	const allowed      : any                                      = getAccessPermissions(currentUser)
	const scheduleAllowance                                       = getAccessPermissions(currentUser, "schedules")
	const enrollmentAllowance                                     = getAccessPermissions(currentUser, "enrollments")
	let   recordFormRef                                           = useRef<HTMLFormElement >(null)
	const [suffix, setSuffix]                                     = useState<string | null>
		(currentUserCampus == null ? null : currencySuffix[campusRef[currentUserCampus].company.country_id - 1])
	const [defaultSearch, setDefaultSearch]                       = useState<any>
	({
		q          : "",
		statusId   :  courseLevelStatus.ACTIVE,
		categoryId :  0,
		tags       : [],
		mentors    : [],
		courses    : [],
		mentorRef  : {}
	})

	const [customSearch, setCustomSearch]                         = useState<any>(defaultSearch)

	const unedit = ()=>
	{
		if(editableCourse && editableCourse.id == null)
		{
			setSelectedCourse(null)
		}

		setEditableCourse(null)
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	const showPrompt = async(level : any)=>
	{
		setUIStatus(UIState.NORMAL)

		if(level.id == null)
		{
			const recordCollationFailure : string = "Información relativa al módulo no pudo ser extraída"

			dispatch(enableIdleLockSwitch())

			try
			{
				let result : any = await AssignmentsService.searchAssignments
					({q : "", tagIds : _.map(level.course.tags, "id"), statusId : assignmentStatus.ACTIVE}, undefined, 100)

				if(result.status == 200 || result.status == 204)
				{
					setInitAssignments
						(result.data.map((assignment : any, index : number) => ({...assignment, refIndex : "A_" + assignment.id})))

					if(noCampusesProvided)
					{
						setSelectedLevel(level)
					}
					else
					{
						let result0 : any = await EmployeesService.searchEmployees
						(
							campusRef[currentUserCampus].id,
							{
								q           : "",
								onlyMentors : true,
								statusId    : employeeStatus.ACTIVE
							},
							undefined,
							100
						)

						if(result0.status == 200 || result0.status == 204)
						{
							setInitMentors
								(result0.data.map((mentor : any, index : number) => ({...mentor, refIndex : "M_" + mentor.id})))

							let result1 : any = await StoreService.searchArticles
							(
								campusRef[currentUserCampus].id,
								{
									q          : "",
									tagIds     : _.map(level.course.tags, "id"),
									categoryId : level.course.category_id,
									statusId   : articleStatus.AVAILABLE,
									onlyKits   : true
								},
								undefined,
								100
							)

							if(result1.status == 200 || result1.status == 204)
							{
								setInitKits
									(result1.data.map((kit : any, index : number) => ({...kit, refIndex : "K_" + kit.id })))

								setSelectedLevel(level)
							}
							else
							{
								procedureComplaint(recordCollationFailure)
							}
						}
						else
						{
							procedureComplaint(recordCollationFailure)
						}
					}
				}
				else
				{
					procedureComplaint(recordCollationFailure)
				}
			}
			catch(error)
			{
				console.log(error)
				procedureComplaint(recordCollationFailure)
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
			}
		}
		else
		{
			setInitMentors([])
			setSelectedLevel(level)
		}
	}

	const showDetailPrompt = async (schedule : any)=>
	{
		let agenda : any = {}

		if(schedule.id != null && schedule.status_id == scheduleStatus.ACTIVE)
		{
			const recordCollationFailure : string = "Información relativa al módulo no pudo ser extraída"

			dispatch(enableIdleLockSwitch())

			try
			{
				let result : any = await SchedulesService.searchSchedules
				(
					campusRef[currentUserCampus].id,
					{
						startValue   : schedule.startValue,
						endValue     : schedule.endValue,
						classroomIds : [schedule.classroom.id],
						exceptionId  : schedule.id,
						statusId     : scheduleStatus.ACTIVE
					}
				)

				if(result.status == 200 || result.status == 204)
				{
					setSelectedSchedule({...schedule, matchingHoursData : result.data})
				}
				else
				{
					procedureComplaint(recordCollationFailure)
				}
			}
			catch(error)
			{
				console.log(error)
				procedureComplaint(recordCollationFailure)
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
			}
		}
		else
		{
			const data = {...schedule, matchingHoursData : []}

			setSelectedSchedule(data)
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target){setCustomSearch({...customSearch,[e.target.name] : e.target.value})}
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableLevel({...editableLevel, [e.target.name] : e.target.value})
		}
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(basicInfo.tags.length < 1)
				{
					procedureComplaint
					(
						"No existen etiquetas para ser asignadas a los cursos creados en el registro, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					if(currentUserCampus == null)
					{
						dispatch(disableIdleLockSwitch())
						procedureComplaint("No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado.")
					}
					else
					{
						const catalogRetrievalError : string = "El catálogo de categorías de cursos no pudo ser extraído"

						dispatch(enableIdleLockSwitch())

						try
						{
							const result    = await SimpleDataService.getRecords("categories")

							if(result.status == 200 || result.status == 204)
							{
								let categoryMap : any= {}

								setCategories
								(
									result.data.map
									(
										(category : any)=>
										{
											categoryMap["C_" + category.id] = category

											return category
										}
									)
								)

								setCategoryRef(categoryMap)
								setInitSetup(CATEGORIES)
							}
							else
							{
								procedureComplaint(catalogRetrievalError)
							}
						}
						catch(error)
						{
							procedureComplaint(catalogRetrievalError)
						}
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case CATEGORIES:
						const levelStatusRetrievalError : string = "los estatuses para módulos no pudieron ser extraídos"

						dispatch(enableIdleLockSwitch())

						try
						{
							const result = await CatalogService.getLevelStatuses(sessionToken)

							if(result.status == 200 || result.status == 204)
							{
								let levelStatusMap : any = {};

								setLevelStatuses
								(
									result.data.map
									(
										(status : any)=>
										{
											levelStatusMap["LS_" + status.id] = status

											return status
										}
									)
								)

								setLevelStatusRef(levelStatusMap)
								setInitSetup(LEVEL_STATUSES)
							}
							else
							{
								procedureComplaint(levelStatusRetrievalError)
							}
						}
						catch(error)
						{
							console.log(error)
							procedureComplaint(levelStatusRetrievalError)
							dispatch(disableIdleLockSwitch())
						}
					break;
					case LEVEL_STATUSES:
						if(levelStatuses.length < 1)
						{
							procedureComplaint
							(
								"No hay estatuses de módulo en el registro, " +
								"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const scheduleStatusRetrievalError : string = "los estatuses para horarios no pudieron ser extraídos"

							try
							{
								const result = await CatalogService.getScheduleStatuses(sessionToken)

								if(result.status == 200 || result.status == 204)
								{
									setScheduleStatuses(result.data)
									setInitSetup(SCHEDULE_STATUSES)
								}
								else
								{
									procedureComplaint(scheduleStatusRetrievalError)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(scheduleStatusRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case SCHEDULE_STATUSES:
						if(scheduleStatuses.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay estatuses de horario en el registro, " +
								"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const courseRetrievalError : string = "La lista de cursos registrados no pudo ser extraída"

							try
							{
								const expand     : number = 3
								let   periodList : any    = []
								const result     : any    = await CoursesService.searchCourses
									(campusRef[currentUserCampus], {statusId : 1}, expand)

								if(result.status == 200 || result.status == 204)
								{
									let courseIndex        : any     = {}
									let lvCount            : number  = 0
									let firstCourseLvIndex : number  = 0
									let levelsWithNoKits  : boolean = false
									let levelIndex         : any
									let mentorIndex        : any
									let kitIndex           : any

									setCourses
									(
										result.data.map
										(
											(course : any, courseCount : number)=>
											{
												levelIndex = {}

												course.levels.map
												(
													(level : any)=>
													{
														mentorIndex = {}
														kitIndex    = {}

														if(!levelsWithNoKits && level.kits.length < 1)
														{
															levelsWithNoKits = true;
														}

														level.mentors.map
														(
															(mentor : any)=>
															{
																mentorIndex["M_" + mentor.id] = {...mentor, label : mentor.first_name + " " + mentor.last_name}
															}
														)

														level.kits.map((kit : any) => kitIndex["M_" + kit.id] = kit)

														levelIndex["L_" + level.id]=
														{
															...level, kitRef : kitIndex,
															mentorRef        : mentorIndex,
															assignments      : level.assignments.map
															(
																(assignment : any)=>
																({
																	...assignment, availability_start : assignment.pivot.availability_start,
																	availability_end                  : assignment.pivot.availability_end,
																	availablePoints                   : assignment.pivot.available_points,
																	availabilityStartValue            : new Date
																		(assignment.pivot.availability_start + " 23:00:00"),
																	availabilityEndValue              : new Date
																		(assignment.pivot.availability_end   + " 23:00:00")
																})
															)
														}

														if(lvCount < 1)
														{
															firstCourseLvIndex = courseCount
														}

														lvCount++
													}
												)

												courseIndex["C_" + course.id]=
												{
													...course, levels : Object.values(levelIndex),
													levelRef          : levelIndex,
													refIndex          : "C_" + course.id
												}

												return courseIndex["C_" + course.id]
											}
										)
									)

									setLevelCount(lvCount)
									setCourseRef(courseIndex)
									setInitSetup(COURSES)

									if(levelsWithNoKits)
									{
										listWarnings({...warningReport, courseKits : "Hay módulos sin kits asociados"})
									}
								}
								else
								{
									procedureComplaint(courseRetrievalError)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(courseRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case COURSES:
						if(Object.keys(courseRef).length < 1)
						{
							procedureComplaint
								("No hay cursos en el registro, por lo que el acceso a este módulo permanecerá deshabilitado")
						}
						else
						{
							const employeeRetrievalError : string = "La lista de profesores no pudo ser extraída"

							try
							{
								const result = await EmployeesService.searchEmployees
									(campusRef[currentUserCampus].id, {roleIds : [baseRole.MENTOR], statusId : employeeStatus.ACTIVE})

								if(result.status == 200 || result.status == 204)
								{
									setMentors
										(result.data.map((record : any) => ({...record, label : record.first_name + " " + record.last_name})))

									setInitSetup(MENTORS)
								}
								else
								{
									procedureComplaint(employeeRetrievalError)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(employeeRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case MENTORS:
						if(mentors.length < 1)
						{
							listWarnings({...warningReport, mentors : "No se encontraron profesores en el registro para agregar a horarios"})
						}

						try
						{
							const expand                           : string = "classrooms"
							const searchParams                     : any    = null
							let   periodList                       : any    = []
							let   classroomCounting                : number =  0
							let   buildingIndex                    : any
							let   classroomIndex                   : any
							let   firstBuildingWithClassroomsIndex : number = -1
							const result                           : any    = await BuildingsService.searchBuildings
								(campusRef[currentUserCampus].id, searchParams, expand)

							if(result.status == 200 || result.status == 204)
							{
								if(result.data.length < 1)
								{
									listWarnings
									({
										...warningReport, campuses : "No hay edificios en el registro, " +
											"por lo que no se podrán agregar horarios dentro de los cursos y módulos creados"
									})
								}
								else
								{
									buildingIndex = {}

									setBuildings
									(
										result.data.map
										(
											(building : any, index : number)=>
											{
												classroomCounting += building.classrooms.length
												classroomIndex     = {}

												let formattedBuilding=
												{
													...building, classrooms : building.classrooms.map
													(
														(classroom : any, index0 : number)=>
														{
															classroomIndex["C_" + classroom.id] = {...classroom, label : classroom.name}

															if(firstBuildingWithClassroomsIndex < 0)
															{
																firstBuildingWithClassroomsIndex = index0
															}

															return classroomIndex["C_" + classroom.id]
														}
													)
												}

												formattedBuilding                          = {...formattedBuilding, classroomRef : classroomIndex}
												buildingIndex["B_" + formattedBuilding.id] = formattedBuilding

												return formattedBuilding
											}
										)
									)

									if(classroomCounting < 1)
									{
										listWarnings
										({
											...warningReport, campuses : "No hay aulas en el registro, " +
												"por lo que no se podrán agregar horarios dentro de los cursos y módulos creados"
										})
									}
								}

								setBuildingRef(buildingIndex)
								setPeriods(periodList)
								setFirstUsableBuildingIndex(firstBuildingWithClassroomsIndex)
								setClassroomCount(classroomCounting)
								setInitSetup(BUILDINGS)
							}
							else
							{
								listWarnings({...warningReport, students : "los edificios  no pudieron ser extraídos. "})

								setInitSetup(BUILDINGS)
							}
						}
						catch(error)
						{
							console.log(error)
							listWarnings
							({
								...warningReport, students : "los planteles  no pudieron ser extraídos. " +
									"No se podrán agregar horarios dentro de los cursos y módulos creados"
							})

							setInitSetup(BUILDINGS)
						}
					break;
					case BUILDINGS:
						try
						{
							const result = await PeriodsService.searchPeriods(campusRef[currentUserCampus].id)

							if(result.status == 200 || result.status == 204)
							{
								if(result.data.count < 1)
								{
									listWarnings
									({
										...warningReport, students:
											"No hay periodos registrados, por lo que no se podrá acceder a horarios y matrículas"
									})

									setInitSetup(ENDING)
								}
								else
								{
									setPeriods(result.data.map((period : any) => ({...period, label : period.name})))
									setInitSetup(PERIODS)
								}
							}
							else
							{
								listWarnings
								({
									...warningReport, students:
										"Los periodos no pudieron ser extraídos. No se podrá acceder a horarios y matrículas"
								})

								setInitSetup(ENDING)
							}
						}
						catch(error)
						{
							console.log(error)
							listWarnings
							({
								...warningReport, students:
									"Los periodos no pudieron ser extraídos. No se podrá acceder a horarios y matrículas"
							})

							setInitSetup(PERIODS)
						}
					break;
					case PERIODS:
						try
						{
							const result = await StudentsService.getStudentsCount(campusRef[currentUserCampus].id)

							if(result.status == 200 || result.status == 204)
							{
								setStudentsCount(result.data.count)

								if(result.data.count < 1)
								{
									listWarnings
									({
										...warningReport, students : "No hay alumnos registrados, " +
											"por lo que no se podrá acceder a las matrículas"
									})
								}

								setInitSetup(ENDING)
							}
							else
							{
								listWarnings
								({
									...warningReport, students : "El conteo de estudiantes no pudo ser extraído. " +
										"No se podrá acceder a las matrículas"
								})

								setInitSetup(ENDING)
							}
						}
						catch(error)
						{
							console.log(error)
							listWarnings
							({
								...warningReport, students : "El conteo de estudiantes no pudo ser extraído. " +
									"No se podrá acceder a las matrículas"
							})

							setInitSetup(ENDING)
						}
					break;
					case ENDING:
						setDefaultNewLevel
						({
							name                                    : "",
							code                                    : "",
							description                             : "",
							startValue                              : "",
							endValue                                : "",
							course_id                               : courses[0].id,
							course                                  : courses[0],
							status_id                               : courseLevelStatus.ACTIVE,
							mentors                                 : [],
							kits                                    : [],
							assignments                             : [],
							session_min_hours                       : 1,
							week_hours                              : 1,
							week_min_days                           : 1,
							week_max_days                           : 1,
							subscription_base_price_mxn             : 1000,
							subscription_base_price_hnl             : 1000,
							monthly_billing_base_price_mxn          : 1000,
							monthly_billing_base_price_hnl          : 1000,
							subscription_surcharge_base_cost_mxn    : 1000,
							subscription_surcharge_base_cost_hnl    : 1000,
							monthly_billing_surcharge_base_cost_mxn : 1000,
							monthly_billing_surcharge_base_cost_hnl : 1000,
							second_surcharge_base_cost_mxn          : 1000,
							second_surcharge_base_cost_hnl          : 1000
						})

						if
						(
							periods.length > 0 && (Object.keys(campusRef).length > 0 && classroomCount > 0) &&
								firstUsableBuildingIndex >= 0
						)
						{
							setDefaultNewSchedule
							({
								name                           : "",
								code                           : "",
								notes                          : "",
								billable_months                : 1,
								students_minimum               : 15,
								students_limit                 : 50,
								hours                          : {},
								subscription_price             : 1000,
								monthly_billing_price          : 1000,
								subscription_surcharge_cost    : 1000,
								monthly_billing_surcharge_cost : 1000,
								second_surcharge_cost          : 1000,
								building_id                    : buildings[firstUsableBuildingIndex].id,
								status_id                      : scheduleStatus.ACTIVE,
								first_surcharge_month_day      : 20,
								second_surcharge_month_day     : 21
							})
						}

						setSectionLoaded(true)
						search()
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(() => {setTotalRecords(data.length)}, [data])

	useEffect
	(
		() => 
		{
			if(clearSearchRequested && _.isEqual(defaultSearch, customSearch))search()
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		() => setMentorRefList(Object.keys(customSearch.mentorRef)),
		[customSearch.mentorRef]
	)

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setCustomSearch(defaultSearch)
				setSectionLoaded(false)
				setLevelCount(0)
				setCourseRef({})
				setMentors([])
				setBuildings([])
				setBuildingRef({})
				listWarnings({})
				setPeriods([])
				setFirstUsableBuildingIndex(null)
				setClassroomCount(0)
				setSuffix(currencySuffix[campusRef[currentUserCampus].company.country_id - 1])
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				setInitSetup(SCHEDULE_STATUSES)
			}
		},
		[currentUserCampus, sectionLoaded]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const debounceFn : any = useCallback(_debounce(handleDebounceFn, 1000), [])

	async function handleDebounceFn(campusId : number, term : string)
	{
		setMentorSearchInProgress(true)

		try
		{
			let result : any = await EmployeesService.searchEmployees
				(campusId, {q : term, roleIds : [baseRole.MENTOR], statusId : employeeStatus.ACTIVE}, undefined, 100)

			if(result.status == 200 || result.status == 204)
			{
				setMentorsList(result.data)
			}
			else
			{
				setSearchError("Se produjo un error al realizar la búsqueda")
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			setSearchError("Se produjo un error al realizar la búsqueda")
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally
		{
			setMentorSearchInProgress(false)
		}
	}

	const searchMentors = (term : string)=>
	{
		setCustomSearch({...customSearch, mentorSearchQuery : term})
		debounceFn(campusRef[currentUserCampus].id, term)
	}

	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError = "La búsqueda no pudo completarse"

		try
		{
			dispatch(enableIdleLockSwitch())

			if(extendedFilterShown)
			{
				setExtendedFilterShown(false)
			}

			const result = await CourseLevelsService.searchLevels(campusRef[currentUserCampus], customSearch, isNaN(page) ? 1 : (page || 1))

			if(result.status == 200 || result.status == 204)
			{
				let mentorRef    : any
				let kitRef       : any
				let currentHours : any
				let refIndex     : string
				let hours        : any
				let startMinutes : number
				let startHours   : number
				let endMinutes   : number
				let endHours     : number
				let weekSessions : any
				let mentors      : any

				setPagination(pageGrouping(result.data.current_page, result.data.last_page))
				setData
				(
					result.data.data.map
					(
						(level : any)=>
						{
							//mentorRef is used to store each mentor session half hour---------------------------------------
							mentorRef    = {}
							kitRef       = {}

							level.kits.map((kit : any) => {kitRef["K_" + kit.id] = kit})

							let mentors = []

							mentors = level.mentors.map
							(
								(mentor : any)=>
								{
									refIndex            = "M_" + mentor.id
									mentorRef[refIndex] = {}

									if(mentor.sessions)
									{
										mentor.sessions.map
										(
											(session : any)=>
											{
												currentHours = session.start_hours

												while(currentHours < session.end_hours)
												{
													mentorRef[refIndex][sprintf("DH%d%03d", session.week_day, currentHours)]=
														session.schedule_id

													currentHours += 5
												}
											}
										)
									}

									return {...mentor, label : mentor.first_name + " " + mentor.last_name}
								}
							)

							return{
								selected : false,
								data:
								{
									...level, kitRef : kitRef,
									mentors          : mentors,
									mentorRef        : mentorRef,
									schedules        : (level.schedules == null || level.schedules.length < 1) ? [] : level.schedules.map
									(
										(schedule : any)=>
										{
											hours        = {}
											weekSessions = []

											schedule.sessions.map
											(
												(session : any)=>
												{
													currentHours = session.start_hours
													currentHours = session.start_hours
													startMinutes = (session.start_hours % 10)
													startHours   = (session.start_hours - startMinutes) / 10
													endMinutes   = (session.end_hours % 10)
													endHours     = (session.end_hours - endMinutes) / 10

													weekSessions.push
													(
														weekDays[session.week_day].substr(0, 2) + sprintf
														(
															" %02d:%02d-%02d:%02d",
															startHours, startMinutes * 6, endHours  , endMinutes * 6
														)
													)

													while(currentHours < session.end_hours)
													{
														hours[sprintf("DH%d%03d", session.week_day, currentHours)] = true
														currentHours                                              += 5
													}
												}
											)

											return{
												...schedule, hours : hours,
												weekSessions       : weekSessions,
												campus_id          : schedule.classroom.building.campus_id,
												period             : {...schedule.period, label : schedule.period.name},
												classroom          : {...schedule.classroom, label : schedule.classroom.name},
												mentor             : isEmpty(schedule.mentor)
														? null
															: {...schedule.mentor, label : schedule.mentor.first_name + " " + schedule.mentor.last_name},
											}
										}
									)
								}
							}
						}
					)
				)

				setSelectedRecords(0)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	useEffect
	(
		()=>
		{
			if(editableCourse)
			{
				trigger
				({
					type    : "setIsButtonDisabled",
					payload : !recordFormRef?.current?.checkValidity() || (idleLocked || _.isEqual(editableCourse, selectedCourse))
						|| (UIStatus == UIState.SUCCESS || editableCourse.tags.length < 1)
				})
			}
		},
		[editableCourse, UIStatus]
	)


	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value};
					}

					return record;
				}
			)
		)
	}

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any)=>
				{
					return {...record, selected : selected};
				}
			)
		);

		setSelectedRecords(totalRecords * (+selected))
	}

	const closePrompt = ()=>
	{
		setSelectedLevel(null)
		setEditableLevel(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError = "El registro no pudo ser borrado. Puede que otra información (horarios de otro plantél) dependan de este registro en particular"

					const recordIds : number[] = !isNaN(recordId)
						? [recordId]
							: data.filter
								((item : any, index : number) => item.selected).map((item : any, index : number) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await CourseLevelsService.removeLevels(campusRef[currentUserCampus].id, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	const confirmDetailRemoval = (detail : any)=>
	{
		dispatch
		(
			displayNotice
			({
				message   : "¿Eliminar horario?",
				heading   : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure : async ()=>
				{
					const scheduleRemovalError : string = "El comando no pudo ser procesado. " +
						"Asegúrese de que ninguna otra información dependa de este registro en específico"

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await SchedulesService.removeSchedules(campusRef[currentUserCampus].id, [detail.id])

						if(result.status == 200 || result.status == 204)
						{
							setData(data.map((record : any, index : number) => {return {selected : false, data : record}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(scheduleRemovalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(scheduleRemovalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  pagination={pagination}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  allowedUpdate={allowed.update}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  searchIcon={<CustomIcon name="level" />}
		  extendedFilterShown={extendedFilterShown}
		  clearSearchRequested={clearSearchRequested}
		  setExtendedFilterShown={setExtendedFilterShown}
		  generalPlaceHolder={"Nombre, código, descripción"}
		  addingPromptFn={() => showPrompt(defaultNewLevel)}
		  extendedFilterViewClosing={() => setExtendedFilterShown(false)}
		  filterSummary=
		  {
			[
				{
					label   : "Estatus",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{customSearch.statusId < 0 ? "Mostrar todo" : levelStatusRef["LS_" + customSearch.statusId].name}
					</p>
				},
				customSearch.categoryId < 1 ? null :
				{
					label   : "Categoría",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{categoryRef["C_" + customSearch.categoryId].name}
					</p>
				},
				customSearch.tags.length < 1 ? null : 
				{
					label   : "Etiquetas",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.tags.map
						(
							(tag : any) => <div key={"t_" + tag.id} className="badge rounded-pill bg-primary">
								{tag.name}
							</div>
						)
					}</div>
				},
				customSearch.courses.length < 1 ? null : 
				{
					label   : "Cursos",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.courses.map
						(
							(course : any) => <div key={"c_" + course.id} className="badge rounded-pill bg-primary">
								{course.name}
							</div>
						)
					}</div>
				},
				isEmpty(mentorRefList) ? null :
				{
					label   : "Profesores",
					display : <div>{
						mentorRefList.length < 2 ? (customSearch.mentorRef[mentorRefList[0]].first_name + " " + customSearch.mentorRef[mentorRefList[0]].last_name) : <>
							<Popover isOpen={mentorListDisplay} onClickOutside={() => setMentorListDisplay(false)}
							  positions={["bottom"]} content=
							  {<div style=
								{{
									overflowY     : "auto",
									maxHeight     : "40vh",
									background    : "var(--main-bg)",
									width         : "440px",
									display       : "flex",
									flexDirection : "column"
								  }}
							  >{
								(mentorRefList.length < 1) ? "" : mentorRefList.filter((key : any) => key && customSearch.mentorRef[key]).map
								(
									(key : string) => <div key={key}>
										{customSearch.mentorRef[key].first_name + " " + customSearch.mentorRef[key].last_name}
									</div>
								)
							  }</div>}
							><button onClick={() => setMentorListDisplay(!mentorListDisplay)} className="btn btnVeryPeri"
							  disabled={idleLocked} type="button"
							>
								[ {mentorRefList.length} ]
								&emsp;
								<FontAwesomeIcon icon={solid("square-caret-down")} />
							</button></Popover>
						</>
					}</div>
				}
			].filter((setting : any) => setting)
		  } advancedSearchForm=
		  {<div className="container">
			<div className="row">
				<div className="col-sm-6">
					<fieldset>
						<legend><label style={{position : "relative", top : "4px"}}>
							Estatus
						</label></legend>
						<button className="form-control btn btn-default" disabled={idleLocked || customSearch.statusId < 0}
						  type="button" onClick={() => setCustomSearch({...customSearch, statusId : -1})} style={{textAlign : "left"}}
						>
							<FontAwesomeIcon icon={customSearch.statusId < 0 ? solid("circle-dot") : solid("circle")} />
							<span style={{paddingLeft : "5px"}}>
								Mostrar todo
							</span>
						</button>
						{
							scheduleStatuses.map
							(
								(status : any) => <button onClick={() => setCustomSearch({...customSearch, statusId : status.id})}
								  disabled={idleLocked || customSearch.statusId == status.id} className="form-control btn btn-default"
								  type="button" key={"S_" + status.id} style={{textAlign : "left"}}
								>
									<FontAwesomeIcon icon={customSearch.statusId == status.id ? solid("circle-dot") : solid("circle")} />
									<span style={{paddingLeft : "5px"}}>
										{status.name}
									</span>
								</button>
							)
						}
					</fieldset>
					<fieldset>
						<legend><label style={{position : "relative", top : "4px"}}>
							Categoría
						</label></legend>
						<div className="selectContainer" style={{marginBottom : "unset", height : "32px"}}>
							<select value={customSearch.categoryId} disabled={idleLocked} name="categoryId"
							  className="btn btn-primary text-start" onChange={handleSearchChange} id="categoryId"
							  style={{height : "30px", marginTop : "unset"}}
							>
								<option value={-1}>
									Todas
								</option>
								{
									categories.map
									(
										(record : any, i : any) => <option key={i} value={record.id}>
											{record.name}
										</option>
									)
								}
							</select>
							<div className="iconRight" style={{pointerEvents : "none"}}>
								<CustomIcon name="rowDown" />
							</div>
						</div>
					</fieldset>
				</div>
				<div className="col-sm-6">
					<fieldset>
						<legend><label style={{position : "relative", top : "4px"}}>
							Cursos
						</label></legend>
						<Multiselect onSelect={(courses : any) => setCustomSearch({...customSearch, courses : courses})} options={courses}
						  placeholder="Seleccione" selectedValues={[...customSearch.courses]} emptyRecordMsg="No hay más opciones"
						  onRemove={(courses : any) => setCustomSearch({...customSearch, courses : courses})} displayValue="name"
						/>
					</fieldset>
					<fieldset>
						<legend><label style={{position : "relative", top : "4px"}}>
							Etiquetas
						</label></legend>
						<Multiselect emptyRecordMsg="No hay más opciones" selectedValues={customSearch.tags} displayValue="name"
						  onRemove={(tags : any) => setCustomSearch({...customSearch, tags : tags})} placeholder="Seleccione"
						  onSelect={(tags : any) => setCustomSearch({...customSearch, tags : tags})} options={basicInfo.tags}
						/>
					</fieldset>
				</div>
			</div>
			<fieldset>
				<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
					Profesores
				</label></legend>
				<Popover isOpen={mentorPopoverOpen} onClickOutside={() => setMentorPopoverOpen(false)} positions={["top"]}
				  content=
				  {<div style=
					{{
						overflowY     : "auto",
						maxHeight     : "40vh",
						background    : "var(--main-bg)",
						width         : "440px",
						display       : "flex",
						flexDirection : "column-reverse"
					  }}
				  >
					{
						(mentorsList.length < 1) ? "" : mentorsList.filter((mentor : any) => mentor).map
						(
							(mentor : any, index : number) => <div key={"M_" + index}
							  style={{display : "inline-grid", padding : "5px", width : "100%"}}
							><div style={{display : "grid", margin : "0px 5px"}}><button type="button" key={"M_" + index}
							  className={"btn btn-" + (customSearch.mentorRef["M_" + mentor.id] == null ? "default" : "primary")}
							  onClick={() => setCustomSearch({...customSearch, mentorRef : {...customSearch.mentorRef, ["M_" + mentor.id] : mentor}})}
							  disabled={idleLocked || customSearch.mentorRef["M_" + mentor.id]} style=
							  {{
								whiteSpace    : "nowrap",
								overflow      : "hidden",
								textOverflow  : "ellipsis",
								textAlign     : "left",
							  }}
							><span style={{paddingLeft : "10px"}}>
								{mentor.first_name + " " + mentor.last_name}
							</span></button></div></div>
						)
					}
					<div className="text-center" style={{color : "#C7C7C7"}}>
						No hay más resultados.
					</div>
				  </div>}
				><div>
					<input onChange={(e : any) => searchMentors(e.target.value)} disabled={idleLocked || clearSearchRequested}
					  style={{flexGrow : 1, background : "#F3F8FF", width : "100%", height : "44px", border : "1px solid #8F91DA"}}
					  value={customSearch.mentorSearchQuery || ""} id="mentorSearchQuery" maxLength={50} name="employeeSearchQuery"
					  onClick={() => setMentorPopoverOpen(!mentorPopoverOpen)} placeholder="Nombre, teléfono, etc." type="text"
					/>
					<div style={{height : "10px"}}>
						{!mentorSearchInProgress ? "" : <div className="loader"></div>}
					</div>
				</div></Popover>
				{
					mentorRefList.length < 1 ? "" : <>
						<hr />
						{
							mentorRefList.filter((key : any) => key && customSearch.mentorRef[key]).map
							(
								(key : any) => <div key={key}
								  style={{display : "flex", alignItems : "stretch", border : "1px solid #C7C7C7", borderRadius : "15px", padding : "10px"}}
								>
									<p style={{flexGrow	 : 1, wordBreak : "break-word", margin : "unset"}}>
										{customSearch.mentorRef[key].first_name + " " + customSearch.mentorRef[key].last_name}
									</p>
									<button style={{...styles.btnCloseModal, flexGrow : 0}} type="button" onClick=
									  {
										()=>
										{
											let mentorMap : any = {...customSearch.mentorRef}

											delete mentorMap[key]

											setCustomSearch({...customSearch, mentorRef : mentorMap})
										}
									  }
									>
										<FontAwesomeIcon style={{ height: "100%", position : "relative", bottom : "1px"}} icon={solid("times")}/>
									</button>
								</div>
							)
						}
					</>
				}
			</fieldset>
		  </div>}
		>
			{
				Object.keys(warningReport).map
				(
					(code : string) => <div style={{padding : "unset", position : "sticky", left : "0px"}} key={"warn_" + code}
					  className="callout callout-warning"
					><table style={{width : "100%"}}><tbody><tr>
						<td style={{padding : "16px", verticalAlign : "middle"}}>
							<FontAwesomeIcon icon={solid("triangle-exclamation")} />
						</td>
						<td style={{width : "100%"}}>
							{warningReport[code]}
						</td>
						<td style={{verticalAlign : "top"}}><button className="btn btn-default" style={{padding : "unset"}}
						  type="button" onClick=
						  {
							()=>
							{
								let warn = warningReport

								delete warn[code]

								listWarnings({...warn})
							}
						  }
						>
							<FontAwesomeIcon icon={solid("times")} style={{margin : "20px 15px 0px"}} />
						</button></td>
					</tr></tbody></table></div>
				)
			}
			{
				(data == null || data.length < 1)
					?
				<>
					<hr />
					<div style={{padding : "25px"}}>
						Sin resultados.
					</div>
					<hr />
				</>
					:
				<table className="specialTable">
					<thead><tr key="levelsHeader">
						{
							!allowed.delete ? "" : <th><button className="btn btn-default" onClick={toggleSelectAll}
							  disabled={idleLocked || saveSuccess} type="button"
							>
								{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
							</button></th>
						}
						<th>
							id
						</th>
						<th>
							Curso
						</th>
						<th>
							Nombre
						</th>
						<th>
							Código
						</th>
						<th>
							Estatus
						</th>
						<th>
							Descripción
						</th>
						<th />
					</tr></thead>
					<>{
						data.map
						(
							(record : any, index : number) => <tbody className="course" key={"campus" + index}>
								<tr>
									{
										!allowed.delete ? "" : <td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
											<button type="button" className="btn btn-default" disabled={idleLocked}
											  onClick={() => toggleRecordSelection(index)}
											>
											{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
										</button></td>
									}
									<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
										{record.data.id}
									</td>
									<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
										[{record.data.course?.code}]
										{record.data.course?.name}
									</td>
									<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
										{record.data.name}
									</td>
									<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
										{record.data.code}
									</td>
									<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
										{record.data.status?.name}
									</td>
									<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
										{record.data.description}
									</td>
									<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}><div style={{display : "flex"}}>
										<button className="button btn btn-default" disabled={idleLocked}
										  style={{display : "table-column"}} type="button" onClick=
										  {
											() => showDetailPrompt
											({
												...defaultNewSchedule, level   : record.data,
												course                         : record.data.course,
												course_id                      : record.data.course.id,
												level_id                       : record.data.id,
												session_min_hours              : record.data.session_min_hours || 1,
												week_hours                     : record.data.week_hours        || 1,
												week_min_days                  : record.data.week_min_days     || 1,
												week_max_days                  : record.data.week_max_days     || 1,
												subscription_price             : record.data["subscription_base_price" + suffix],
												monthly_billing_price          : record.data["monthly_billing_base_price" + suffix],
												subscription_surcharge_cost    : record.data["subscription_surcharge_base_cost" + suffix],
												monthly_billing_surcharge_cost : record.data["monthly_billing_surcharge_base_cost" + suffix],
												second_surcharge_cost          : record.data["second_surcharge_base_cost" + suffix]
											})
										  }
										>
											<CustomIcon name="addSchedule" />
										</button>
										<button disabled={idleLocked} className="button btn btn-default" type="button" style=
										  {{
											display    : "table-column",
											color      : "var(--txt-color)",
											paddingTop : "10px"
										  }} onClick=
										  {
											()=>
											{
												showPrompt
												({
													...record.data, description             : record.data.description || "",
													subscription_base_price_mxn             : record.data.subscription_base_price_mxn || 0,
													subscription_base_price_hnl             : record.data.subscription_base_price_hnl || 0,
													monthly_billing_base_price_mxn          : record.data.monthly_billing_base_price_mxn || 0,
													monthly_billing_base_price_hnl          : record.data.monthly_billing_base_price_hnl || 0,
													subscription_surcharge_base_cost_mxn    : record.data.subscription_surcharge_base_cost_mxn || 0,
													subscription_surcharge_base_cost_hnl    : record.data.subscription_surcharge_base_cost_hnl || 0,
													monthly_billing_surcharge_base_cost_mxn : record.data.monthly_billing_surcharge_base_cost_mxn || 0,
													monthly_billing_surcharge_base_cost_hnl : record.data.monthly_billing_surcharge_base_cost_hnl || 0,
													second_surcharge_base_cost_mxn          : record.data.second_surcharge_base_cost_mxn || 0,
													second_surcharge_base_cost_hnl          : record.data.second_surcharge_base_cost_hnl || 0,
													mentors                                 : record.data.mentors.map
													(
														(mentor : any)=>
														({
															...mentor,
															deletion : false,
															refIndex : "M_" + mentor.id,
															stocked  : true,
														})
													),
													kits : record.data.kits.map
													(
														(kit : any)=>
														({
															...kit, deletion : false,
															refIndex : "K_" + kit.id,
															stocked  : true
														})
													),
													assignments : record.data.assignments.map
													(
														(assignment : any)=>
														({
															...assignment, refIndex : "A_" + assignment.id,
															points                  : assignment.pivot.available_points,
															deletion                : false,
															stocked                 : true,
															availabilityStartValue  : new Date
															(
																assignment
																	.pivot
																	.availability_start
																	.replaceAll("-", "/") + " 23:00:00"
															),
															availabilityEndValue    : new Date
															(
																assignment
																	.pivot
																	.availability_end
																	.replaceAll("-", "/") + " 23:00:00"
															)
														})
													)
												})
											}
										  }
										>
											<FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
										</button>
										{
											!allowed.delete ? "" : <button className="button btn btn-default" disabled={idleLocked}
											  onClick={() => confirmRemoval(record.data.id)} style={{display : "table-column"}}
											  type="button"
											>
												<CustomIcon name="bTrash" />
											</button>
										}
									</div></td>
								</tr>
{
	record.data.schedules == null || record.data.schedules.length < 1 ? "" : <tr>
		<td colSpan={8} className="notNow" style=
		  {{
			outline     : "10px solid var(--main-bg-color)",
			background  : "var(--main-bg-color)",
			paddingLeft : "25px"
		  }}
		><fieldset>
			<legend>
				Horarios
			</legend>
			<table style={{width : "100%"}}><tbody>{
				record.data.schedules.map
				(
					(detail : any)=>
					<tr key={"s" + detail.id}>
						<td ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}}>
							[{detail.code}]
						</td>
						<td style={{background : "#F3F8FF"}}>{
							detail.weekSessions.map
							(
								(session : any) => <div key={"S_" + session}>
									{session}
								</div>
							)
						}</td>
						<td>
							{detail.name}
						</td>
						<td>
							{detail.status.name}
						</td>
						<td>
							{detail.period.name}
						</td>
						<td>
							{detail.classroom.building.name}
						</td>
						<td>
							{detail.classroom.name}
						</td>
						<td><div style={{display : "flex", float : "right"}}>
							{
								(!enrollmentAllowance.read || studentsCount < 1) ? "" : <button disabled={idleLocked}
								  className="button btn btn-default" type="button" onClick=
								  {
									() => setSelectedEnrollment
									({
										...detail, course : record.data.course,
										course_id         : record.data.course_id,
										level             :
										{
											...record.data, kits :
											[
												...record.data.kits.filter
												(
													(kit : any) => kit.type.bool_is_kit &&
													(kit.status_id == articleStatus.AVAILABLE && kit.company_id == campusRef[currentUserCampus].company_id)
												)
											]
										},
										students          : detail.enrollments.map
										(
											(enrollment : any)=>
											{
												let invoicedKitRef : any = {}

												enrollment.kits.map
												(
													(kit : any)=>
													{
														if(kit.pivot.invoice_id)
														{
															invoicedKitRef["IK_" + kit.id] = kit
														}
													}
												)

												return{
													...enrollment.student, stocked : true,
													deletion                       : false,
													refIndex                       : "S_" + enrollment.student.id,
													scheduleId                     : detail.id,
													enrollment_id                  : enrollment.id,
													tuitions                       : enrollment.tuitions,
													tuitionInvoiceIds              : _.map
													(
														enrollment.tuitions.filter((tuition : any) => tuition.invoice_id),
														"invoice_id"
													),
													invoicedKits                   : Object.values(invoicedKitRef),
													extraKits                      : [...enrollment.kits.filter
														((kit : any) => record.data.kitRef["K_" + kit.id] == null && kit.pivot.invoice_id == null)],
													editableKits                   : [...enrollment.kits.filter
													(
														(kit : any) => kit.pivot.invoice_id == null &&
														(
															record.data.kitRef["K_" + kit.id] && 
															(
																record.data.kitRef["K_" + kit.id].type.bool_is_kit > 0 &&
																record.data.kitRef["K_" + kit.id].status_uid == articleStatus.AVAILABLE
															)
														)
													)],
													availableKits                  : record.data.kits.length < 1 ? [] : record.data.kits.filter
													(
														(kit : any) => invoicedKitRef["IK_" + kit.id] == null &&
														(
															record.data.kitRef["K_" + kit.id] &&
															(
																record.data.kitRef["K_" + kit.id].type.bool_is_kit > 0 &&
																record.data.kitRef["K_" + kit.id].status_id == articleStatus.AVAILABLE
															)
														)
													)
												}
											}
										)
									})
								  }
								>
									<CustomIcon name="student"
									  style={{fill : detail.enrollments && detail.enrollments.length > 0 ? "#349EFF" : "#000000"}}
									/>
								</button>
							}
							<button disabled={idleLocked} className="button btn btn-default" type="button" style=
							  {{
								color      : "var(--txt-color)",
								paddingTop : "10px"
							  }} onClick=
							  {
								() => showDetailPrompt
								({
									...detail, course  : record.data.course,
									building_id        : detail.classroom.building_id,
									course_id          : record.data.course.id,
									level_id           : record.data.id,
									level              : record.data,
									startValue         : new Date(detail.start_date + " 23:00:00"),
									endValue           : new Date(detail.end_date + " 23:00:00")
								})
							  }
							>
								<FontAwesomeIcon flip="horizontal" icon={solid("eye")} />
							</button>
							{
								!scheduleAllowance.delete ? "" : <button disabled={idleLocked || detail.enrollments.length > 0}
								  className="button btn btn-default" onClick={() => confirmDetailRemoval(detail)} title=
								  {
									detail.enrollments.length < 1 ? "" : "Debe remover los alumnos del curso antes de poder eliminarlo"
								  }
								>
									<CustomIcon name="bTrash" />
								</button>
							}
						</div></td>
					</tr>
				)
			}</tbody></table>
		</fieldset></td>
	</tr>
}
							</tbody>
						)
					}</>
				</table>
			}
		</SectionContainer>
		{
			selectedLevel == null ? "" : <LevelPrompt afterCloseFn={search} closeFn={() => setSelectedLevel(null)}
			  levelStatuses={levelStatuses} selectedLevel={selectedLevel} initMentors={initMentors} initKits={initKits}
			  initAssignments={initAssignments} courseRef={courseRef} allowedUpdate={allowed.update}
			/>
		}
		{
			selectedSchedule == null ? "" : <SchedulePrompt selectedSchedule={selectedSchedule} afterCloseFn={search}
			  buildingRef={buildingRef} closeFn={() => setSelectedSchedule(null)} scheduleStatuses={scheduleStatuses}
			  periods={periods} allowedUpdate={scheduleAllowance.update}
			/>
		}
		{
			selectedEnrollment == null || selectedUser ? "" : <EnrollmentPrompt closeFn={() => {setSelectedEnrollment(null)}}
			  selectedEnrollment={selectedEnrollment} allowance={enrollmentAllowance} profileViewingFn={setSelectedUser}
			  afterCloseFn={search}
			/>
		}
		{
			selectedUser == null ? "" : <UserPrompt promptCloseFn={()=> setSelectedUser(null)} selectedUser={selectedUser} />
		}
	</>
}

export default Levels
