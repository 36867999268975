import { useNavigate }
	from "react-router-dom"
import { useSelector }
	from "react-redux"
import { solid }
	from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon }
	from "@fortawesome/react-fontawesome"

import Constants
	from "../../../../../constants"
import { RootState }
	from "./../../../../../globals"
import { styles }
	from "./style"

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
	/**
	 * Student Data.
	 */
	student: any;
	/**
	 * balance data, positive balance, expired balance.
	 */
	balance: any;
}

const Header: React.FC<HeaderProps> = ({ student, balance }) => {
	const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value)
	const navigate: any = useNavigate()

	return (
		<div style={styles.container}>
			<div style={styles.header}>
				<button type="button"
					className="btn btn-default"
					disabled={idleLocked}
					onClick={() => navigate("/students")}>
					<FontAwesomeIcon icon={solid("chevron-left")} size="2x" />
				</button>
				<h3>
					{Constants.Screens.Finances.accountStatement.header}
				</h3>
			</div>
			<div style={styles.studentDataRow}>
				<div style={styles.subtitleContainer}>
					<label style={styles.subtitle}>
						{Constants.Common.name}:
					</label>
					<span style={styles.studentDataText}>
						{student.firstName}
					</span>
				</div>
				<div style={styles.subtitleContainer}>
					<label style={styles.subtitle}>
						{Constants.Common.lastName}:
					</label>
					<span style={styles.studentDataText}>
						{student.lastName}
					</span>
				</div>
				<div style={styles.subtitleContainer}>
					<label style={styles.subtitle}>
						{Constants.Common.correlative}:
					</label>
					<span style={styles.studentDataText}>
						{student.identityCode}
					</span>
				</div>
			</div>
			<div style={styles.balanceContainer}>
				<div style={styles.financeTextContainer}>
					<span style={styles.financeText}>
						{Constants.Screens.Finances.accountStatement.subtitle}
					</span>
				</div>
				<div style={styles.balanceGrid}>
					<div style={styles.balanceRow}>
						<p style={styles.balanceText}>
							{Constants.Screens.Finances.accountStatement.dueMonthlyBalance}:
						</p>
						<p style={styles.pendingAmount}>
							{balance.expiredMonthlyBalance != null ? balance.expiredMonthlyBalance : "-"}
						</p>
					</div>
					<div style={styles.balanceRow}>
						<p style={styles.balanceText}>
							{Constants.Screens.Finances.accountStatement.monthlyBalance}:
						</p>
						<p style={styles.pendingAmountBlackText}>
							{balance.monthlyBalance != null ? balance.monthlyBalance : "-"}
						</p>
					</div>
					<div style={styles.balanceRow}>
						<p style={styles.balanceText}>
							{Constants.Screens.Finances.accountStatement.kitBalance}:
						</p>
						<p style={styles.pendingAmount}>
							{balance.kitsBalance != null ? balance.kitsBalance : "-"}
						</p>
					</div>
					<div style={styles.balanceRow}>
						<p style={styles.balanceText}>
							{Constants.Screens.Finances.accountStatement.otherBalance}:
						</p>
						<p style={styles.pendingAmount}>
							{balance.othersBalance != null ? balance.othersBalance : "-"}
						</p>
					</div>
					<div style={styles.balanceRow}>
						<p style={styles.balanceText}>
							{Constants.Screens.Finances.accountStatement.positiveBalance}:
						</p>
						<p style={styles.positiveBalance}>
							{balance.positiveBalance != null ? balance.positiveBalance : "-"}
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header