import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paymentStatus } from '../../constants';

interface Currency {
    id: number;
    name: string;
    code: string;
    symbol: string;
}

const initialState: any = {
    currencies: <Currency[]>[],
    campuses: [],
    countries: [],
    recipientAccounts: [],
    paymentMethods: [],
    allowedPaymentMethods: [],
    paymentStatuses: [],
    enrollmentStatuses: []
};

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        setCurrencies(state, action: PayloadAction<Currency[]>) {
            state.currencies = action.payload;
        },
        setPaymentStatuses: (state, action: PayloadAction<any>) => {
            const paymentStatuses = action.payload.map((paymentStatus: any) => ({
                ...paymentStatus,
                id: paymentStatus.id,
                value: paymentStatus.id,
                label: paymentStatus.name
            }));
            state.paymentStatuses = paymentStatuses;
        },
        setEnrollmentStatuses: (state, action: PayloadAction<any>) => {
            const enrollmentStatuses = action.payload.map((enrollmentStatus: any) => ({
                ...enrollmentStatus,
                id: enrollmentStatus.id,
                value: enrollmentStatus.id,
                label: enrollmentStatus.name
            }));
            state.enrollmentStatuses = enrollmentStatuses;
        },
        setCampuses(state, action: PayloadAction<any>) {
            state.campuses = action.payload;
        },
        setRecipientAccounts(state, action: PayloadAction<any>) {
            state.recipientAccounts = action.payload;
        },
        setPaymentMethods(state, action: PayloadAction<any>) {
            state.paymentMethods = action.payload;
            const paymentMethods = action.payload.map((paymentMethod: any) => ({
                ...paymentMethod,
                value: paymentMethod.id,
                label: paymentMethod.name,
                id: paymentMethod.id,
                accounts: paymentMethod.accounts.map((account: any) => ({
                    ...account,
                    label: account.name,
                    value: account.id,
                    description: account.description
                }))
            }));
            state.allowedPaymentMethods = paymentMethods;
        }
    }
});

export const {
    setCurrencies,
    setPaymentStatuses,
    setEnrollmentStatuses,
    setCampuses,
    setRecipientAccounts,
    setPaymentMethods
}
    = catalogSlice.actions;
export default catalogSlice.reducer;