import axios from "axios";

const RESOURCE_URL_V2 = process.env.REACT_APP_API_URL_V2 + "/admin/campus/";

export class PaymentService {
    config = (sessionToken: string | null, sendFiles: boolean = false) => {
        return {
            headers:
            {
                "Content-Type": sendFiles ? "multipart/form-data" : "application/json",
                Authorization: `Bearer ${sessionToken}`
            }
        };
    }

    isValidString = (value: string | null | undefined): boolean => {
        return typeof value === "string" && value.trim() !== "";
    }

    getPaginatedPayments = async (sessionToken: string | null, uri: string) => {
        let response = await axios.get(uri, this.config(sessionToken));
        return response;
    }

    getPaymentsByCampusId = async (sessionToken: string | null, campusId: number, perPage: number, criteria?: any) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/payments?perPage=${perPage}`;
        if (criteria && typeof criteria === 'object' && Object.keys(criteria).length > 0) {
            try {
                const criteriaJson = JSON.stringify(criteria);
                uri = `${uri}&criteria=${encodeURIComponent(criteriaJson)}`;
            } catch (error) {
                console.error("Error serializando criteria a JSON:", error);
                throw new Error("Invalid criteria format");
            }
        }
        let response = await axios.get(uri, this.config(sessionToken));
        return response;
    }

    getPaymentById = async (sessionToken: string | null, campusId: number, paymentId: number) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/payments/${paymentId}/_details`;
        let response = await axios.get(uri, this.config(sessionToken));
        return response;
    }

    cancelPaymentById = async (sessionToken: string | null, campusId: number, paymentId: number, payload: any) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/payments/${paymentId}/_cancel`;
        let response = await axios.post(uri, payload, this.config(sessionToken));
        return response;
    }

    payOwedItems = async (sessionToken: string | null, campusId: number, studentId: number, payload: any) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/payments`;
        const formData = new FormData();

        formData.append("paidAmount", payload.paidAmount);
        formData.append("paymentDate", payload.paymentDate);
        formData.append("paymentMethodId", payload.paymentMethodId);
        formData.append("accountId", payload.accountId);
        formData.append("reference", payload.reference || "");
        formData.append("notes", payload.notes || "");
        payload.attachedFiles.forEach((file: File, index: number) => {
            formData.append(`attachedFiles[${index}]`, file);
        });
        formData.append("invoiceItems", JSON.stringify(payload.invoiceItems));

        let response = await axios.post(uri, formData, this.config(sessionToken, true));
        return response;
    }
}