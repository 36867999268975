import React, {
    Suspense,
    lazy
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../../globals";
import { setShowModal } from "../../../../redux/reducers/accountStatementReducer";
import Constants, {
    articleType,
    FINANCES_MODALS
} from "../../../../constants";
import Header from "./header";
import AccountStatementCard from "../../../../components/accountStatementCard";
import ArticleCardComponent from "../../../../components/articlesCard";
import CheckoutModalComponent from "../../../../components/modals/checkout/checkout";
import InvoiceDetailModalComponent from "../../../../components/modals/invoiceDetails/invoiceDetails";
import GenerateInvoiceModalComponent from "../../../../components/modals/generateInvoice/generateInvoice";
import PaymentDetailModalComponent from "../../../../components/modals/payments/detail/paymentDetails";
import EmitPaymentModalComponent from "../../../../components/modals/payments/create/emitPayment";
import CancelInvoiceModalComponent from "../../../../components/modals/cancelInvoice/cancelInvoice";
import CancelPaymentModalComponent from "../../../../components/modals/payments/cancel/cancelPayment";
import UnderlineHeading from "../../../../components/underlineHeading";
import ShoppingCartButtonComponent from "../../../../components/buttons/shoppingCartButton/shoppingCartButton";

const AsideModalComponent = lazy(() => import("../../../../components/modals/asideModal"));

const AccountStatementView = (props: any) => {
    const {
        accountStatementProps,
        tableProps,
        checkoutDataProps,
        invoiceDetailProps,
        paymentDetailProps,
        emitPaymentProps,
        cancelPaymentProps,
        generateInvoiceProps,
        cancelInvoiceProps
    } = props

    const dispatch = useDispatch<AppDispatch>();
    const showModal: boolean = useSelector((state: RootState) => state.rootReducer.accountStatement.showModal);
    const modalContent: any = useSelector((state: RootState) => state.rootReducer.accountStatement.modalContent);
    const generalData: any = useSelector((state: RootState) => state.rootReducer.accountStatement.generalData);
    const payment: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedPayment);
    const invoice: any = useSelector((state: RootState) => state.rootReducer.accountStatement.selectedInvoice);
    const itemsInInvoice: any = useSelector((state: RootState) => state.rootReducer.accountStatement.invoiceItems);
    const totalToPaid: any = useSelector((state: RootState) => state.rootReducer.accountStatement.totalToPaid);
    const totalCheckoutItems: number = useSelector((state: RootState) => state.rootReducer.accountStatement.totalCheckoutItems);

    const renderModalContent = (children: number) => {
        switch (children) {
            case FINANCES_MODALS.CHECKOUT:
                return (
                    <CheckoutModalComponent
                        student={generalData?.student}
                        data={checkoutDataProps.checkoutData}
                        closeModal={checkoutDataProps.closeModal}
                        goToProccessPayment={checkoutDataProps.goToProccessPayment}
                    />
                );
            case FINANCES_MODALS.INVOICE:
                return (
                    <InvoiceDetailModalComponent
                        student={generalData?.student}
                        invoice={invoice}
                        closeModal={invoiceDetailProps.closeModal}
                        showPaymentDetail={invoiceDetailProps.showPaymentDetail}
                        cancelInvoice={invoiceDetailProps.cancelInvoice}
                        proccessPayment={invoiceDetailProps.proccessPayment}
                    />
                );
            case FINANCES_MODALS.GENERATE_INVOICE:
                return (
                    <GenerateInvoiceModalComponent
                        onSuccess={generateInvoiceProps.onSuccess}
                        closeModal={generateInvoiceProps.closeModal}
                    />
                );
            case FINANCES_MODALS.CANCEL_INVOICE:
                return (
                    <CancelInvoiceModalComponent
                        student={generalData?.student}
                        invoice={invoice}
                        closeModal={cancelInvoiceProps.closeModal}
                        onSuccess={cancelInvoiceProps.onSuccess}
                    />
                );
            case FINANCES_MODALS.PAYMENT_DETAIL:
                return (
                    <PaymentDetailModalComponent
                        student={generalData?.student}
                        payment={payment}
                        closeModal={paymentDetailProps.closeModal}
                        handleCancelPayment={paymentDetailProps.handleCancelPayment}
                    />
                );
            case FINANCES_MODALS.EMIT_PAYMENT:
                return (
                    <EmitPaymentModalComponent
                        student={generalData?.student}
                        invoice={invoice}
                        itemsInInvoice={itemsInInvoice}
                        totalToPaid={totalToPaid}
                        isPartialPayment={emitPaymentProps.isPartialPayment}
                        isNewInvoice={emitPaymentProps.isNewInvoice}
                        closeModal={emitPaymentProps.closeModal}
                        cancelEmitPayment={emitPaymentProps.cancelEmitPayment}
                        onSuccess={emitPaymentProps.onSuccess}
                    />
                );
            case FINANCES_MODALS.CANCEL_PAYMENT:
                return (
                    <CancelPaymentModalComponent
                        student={generalData?.student}
                        payment={payment}
                        closeModal={cancelPaymentProps.closeModal}
                        onSuccess={cancelPaymentProps.onSuccess}
                    />
                );
            default:
                dispatch(setShowModal(false));
                return null;
        }
    }

    return (
        <React.Fragment>
            {!showModal && totalCheckoutItems > 0
                ? <ShoppingCartButtonComponent
                    totalItems={totalCheckoutItems}
                    onClickEvent={accountStatementProps.showCheckoutModal} />
                : <React.Fragment />
            }
            <div className="transition-width"
                style={{
                    width: `calc(100% - ${showModal ? "648px" : "0px"})`
                }}>
                {
                    generalData?.balance && generalData?.student
                        ? <Header
                            balance={generalData.balance}
                            student={generalData.student} />
                        : <React.Fragment />
                }
                {generalData?.courses.length > 0 ?
                    generalData.courses.map((course: any, index: number) => {
                        return (
                            <div key={`course-row-${index}`}
                                className="row"
                                style={{
                                    paddingLeft: "30px",
                                    paddingRight: "30px"
                                }}>
                                <div key={`course-col-${index}`}
                                    className="col-lg-12"
                                    style={{ paddingTop: "30px" }}>
                                    <AccountStatementCard
                                        key={course.courseId}
                                        columns={tableProps.tuitionColumns}
                                        data={course}
                                    />
                                </div>
                            </div>
                        )
                    })
                    : <React.Fragment />
                }
                <div className="row"
                    style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        marginBottom: "30px"
                    }}>
                    <div className="col-lg-6"
                        style={{ paddingTop: "30px" }}>
                        <ArticleCardComponent
                            columns={tableProps.articleColumns}
                            data={generalData.articles}
                            header={Constants.Screens.Finances.accountStatement.kitBalance}
                            type={articleType.STANDARD_KIT}
                        />
                    </div>
                    <div className="col-lg-6"
                        style={{ paddingTop: "30px" }}>
                        <ArticleCardComponent
                            columns={tableProps.articleColumns}
                            data={generalData.articles}
                            header={Constants.Screens.Finances.accountStatement.otherBalance}
                            type={articleType.OTHERS}
                        />
                    </div>
                </div>
                <Suspense fallback={<div>Loading...</div>}>
                    <AsideModalComponent
                        showModal={showModal}
                        location="right"
                        style={{ width: "648px" }}>
                        {renderModalContent(modalContent)}
                    </AsideModalComponent>
                </Suspense>

                <div className="row"
                    style={{
                        paddingLeft: "30px",
                        paddingRight: "30px"
                    }}>
                    <UnderlineHeading
                        name={Constants.Common.historial} />
                </div>

                {generalData?.coursesHistory.length > 0 ?
                    generalData.coursesHistory.map((course: any, index: number) => {
                        return (
                            <div key={`course-row-${index}`}
                                className="row"
                                style={{
                                    paddingLeft: "30px",
                                    paddingRight: "30px"
                                }}>
                                <div key={`course-col-${index}`}
                                    className="col-lg-12"
                                    style={{ paddingTop: "30px" }}>
                                    <AccountStatementCard
                                        key={course.courseId}
                                        columns={tableProps.tuitionColumns}
                                        data={course}
                                    />
                                </div>
                            </div>
                        )
                    })
                    : <React.Fragment />
                }

                <div className="row"
                    style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        marginBottom: "30px"
                    }}>
                    <div className="col-lg-6"
                        style={{ paddingTop: "30px" }}>
                        <ArticleCardComponent
                            columns={tableProps.articleColumns}
                            data={generalData.articlesHistory}
                            header={Constants.Screens.Finances.accountStatement.kitBalance}
                            type={articleType.STANDARD_KIT}
                        />
                    </div>
                    <div className="col-lg-6"
                        style={{ paddingTop: "30px" }}>
                        <ArticleCardComponent
                            columns={tableProps.articleColumns}
                            data={generalData.articlesHistory}
                            header={Constants.Screens.Finances.accountStatement.otherBalance}
                            type={articleType.OTHERS}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AccountStatementView;