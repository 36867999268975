import { axiosInstanceV1 } from "./axiosInstance";

export class EnrollmentService {

    async updateStudentEnrollment(
        campusId: number,
        courseId: number,
        levelId: number,
        scheduleId: number,
        enrollmentId: number,
        payload: any
    ) {
        const URL = `/admin/
            campus/${campusId}/
            courses/${courseId}/
            levels/${levelId}/
            schedules/${scheduleId}/
            enrollments/${enrollmentId}`.replace(/\s+/g, '');
        const response = await axiosInstanceV1.delete(URL, { data: payload });
        return response;
    }
}