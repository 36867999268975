import axios from "axios";

const RESOURCE_URL_V2 = process.env.REACT_APP_API_URL_V2 + "/admin/campus/";

export class InvoiceService {
    config = (sessionToken: string | null, sendFiles: boolean = false) => {
        return {
            headers:
            {
                "Content-Type": sendFiles ? "multipart/form-data" : "application/json",
                Authorization: `Bearer ${sessionToken}`
            }
        };
    }

    isValidString = (value: string | null | undefined): boolean => {
        return typeof value === "string" && value.trim() !== "";
    }

    getPaginatedInvoices = async (sessionToken: string | null, uri: string) => {
        let response = await axios.get(uri, this.config(sessionToken));
        return response;
    }

    getInvoiceByCampusId = async (sessionToken: string | null, campusId: number, perPage: number, query?: string) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/invoices?perPage=${perPage}`;
        uri = this.isValidString(query) ? `${uri}&query=${query}` : uri;
        let response = await axios.get(uri, this.config(sessionToken));
        return response;
    }

    getInvoiceById = async (sessionToken: string | null, campusId: number, studentId: number, invoiceId: number) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/invoices/${invoiceId}/_details`;
        let response = await axios.get(uri, this.config(sessionToken));
        return response;
    }

    generateInvoice = async (sessionToken: string | null, campusId: number, studentId: number, payload: any) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/invoices/_generatePaidInvoice`;
        let response = await axios.post(uri, payload, this.config(sessionToken));
        return response;
    }

    updateInvoice = async (sessionToken: string | null, campusId: number, studentId: number, invoiceId: number, payload: any) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/invoices/${invoiceId}`;
        let response = await axios.put(uri, payload, this.config(sessionToken));
        return response;
    }

    cancelInvoice = async (sessionToken: string | null, campusId: number, studentId: number, invoiceId: number, payload: any) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/invoices/${invoiceId}/_cancel`;
        let response = await axios.post(uri, payload, this.config(sessionToken));
        return response;
    }

    addPayment = async (sessionToken: string | null, campusId: number, studentId: number, invoiceId: number, payload: any) => {
        let uri = `${RESOURCE_URL_V2}${campusId}/students/${studentId}/invoices/${invoiceId}/_addPayment`;
        const formData = new FormData();

        formData.append("paidAmount", payload.paidAmount);
        formData.append("paymentDate", payload.paymentDate);
        formData.append("paymentMethodId", payload.paymentMethodId);
        formData.append("accountId", payload.accountId);
        formData.append("reference", payload.reference || "");
        formData.append("notes", payload.notes || "");
        payload.attachedFiles.forEach((file: File, index: number) => {
            formData.append(`attachedFiles[${index}]`, file);
        });

        let response = await axios.post(uri, formData, this.config(sessionToken, true));
        return response;
    }
}