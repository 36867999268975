import { combineReducers } from '@reduxjs/toolkit';
import catalogReducer from "./catalogReducer";
import accountStatementReducer from './accountStatementReducer';
import sessionReducer from './sessionReducer';
import invoicesReducer from './invoicesReducer';
import paymentsReducer from './paymentsReducer';
import scheduleReducer from './scheduleReducer';

const rootReducer = combineReducers({
    catalog: catalogReducer,
    sessionState: sessionReducer,
    invoices: invoicesReducer,
    payments: paymentsReducer,
    accountStatement: accountStatementReducer,
    schedule: scheduleReducer
});

export default rootReducer;