import { axiosInstance } from "./axiosInstance";

const RESOURCE_URL_V2 = "/admin/paymentMethods";

export class PaymentMethodsService {

    getPaymentMethod = async (paymentId?: number) => {
        const uri = `${RESOURCE_URL_V2}/${paymentId}`;
        const response = await axiosInstance.get(uri);
        return response;
    }

    getPaymentMethodsByCountry = async (countryId?: number) => {
        const uri = `${RESOURCE_URL_V2}/_country/${countryId}`;
        const response = await axiosInstance.get(uri);
        return response;
    }

    saveRecord = async (paymentMethod: any) => {
        const saveFunction = paymentMethod.id == null ? this.addPaymentMethod : this.updatePaymentMethod

        const payload = {
            name: paymentMethod.name + "",
            boolPaymentDateUnmodifiable: +paymentMethod.bool_payment_date_unmodifiable,
            boolPaymentReferenceNullified: +paymentMethod.bool_payment_reference_nullified,
            accountIds: paymentMethod.accountIds
        }
        const response = saveFunction(payload, paymentMethod.id);
        return response;
    }

    addPaymentMethod = async (payload: any) => {
        return await axiosInstance.post(RESOURCE_URL_V2, payload)
    }

    updatePaymentMethod = async (payload: any, id: number) => {
        return await axiosInstance.put(`${RESOURCE_URL_V2}/${id}`, payload)
    }

    removePaymentMethods = async (recordIds: any) => {
        let url: string = RESOURCE_URL_V2;
        let payload: any;
        if (recordIds.length < 2) {
            url = RESOURCE_URL_V2 + "/" + recordIds[0]
        }
        else {
            url = RESOURCE_URL_V2
            payload.data = { ids: recordIds.join(",") }
        }
        const response = await axiosInstance.delete(url, payload);
        return response;
    }
}