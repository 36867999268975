import axios
	from "axios"
import store
	from "./../../globals"
import _
	from "lodash"

import {isNotEmpty, URLBuild}
	from "./../../common"

const API_URL      = process.env.REACT_APP_API_URL
const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus"

class SchedulesService
{
	sessionToken = store.getState().sessionToken.value
	campusId     = store.getState().currentUserCampusId.value

	constructor()
	{
		store.subscribe
		(
			()=>
			{
				this.sessionToken = store.getState().sessionToken.value
				this.campusId     = store.getState().currentUserCampusId.value
			}
		)
	}

	async searchEnrollableStudents(enrollmentId : number, searchQuery? :  string, resultsLimit? : number)
	{
		let params : string[] = [];

		if(isNotEmpty(searchQuery))
		{
			params.push("q=" + searchQuery)
		}

		if(resultsLimit && resultsLimit > 0)
		{
			params.push("results=" + resultsLimit)
		}

		return axios.get
		(
			URLBuild
			(
				[API_URL, "admin/campus", this.campusId, "schedules", enrollmentId, "enrollments", "_enrollableStudents"],
				params
			),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	async addEnrollments(enrollment : any, studentRefs : any)
	{
		return axios.post
		(
			URLBuild
			([
				RESOURCE_URL, this.campusId, "courses", enrollment.course_id, "levels", enrollment.level_id, "schedules", enrollment.id,
				"enrollments"
			]),
			studentRefs,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	async updateEnrollments(enrollment : any, studentRefs : any)
	{
		return axios.put
		(
			URLBuild
			([
				RESOURCE_URL, this.campusId, "courses", enrollment.course_id, "levels", enrollment.level_id, "schedules", enrollment.id,
				"enrollments"
			]),
			studentRefs,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	async cancelEnrollment(scheduleId : number, enrollmentId : number, statusId : number, tuitionIds : any)
	{
		return axios.delete
		(
			URLBuild([RESOURCE_URL, this.campusId, "schedules", scheduleId, "enrollments", enrollmentId]),
			{
				data :
				{
					statusId   : statusId,
					tuitionIds : tuitionIds
				},
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + store.getState().sessionToken.value
				}
			}
		).then(response => response)
	}
}

export default new SchedulesService()