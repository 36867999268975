import {
    createAsyncThunk,
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit';
import HttpManager from '../../services/HttpManager';

interface Tuition {
    id: number;
    discount: number;
    typeOfDiscount: number;
}

interface Article {
    id: number;
    discount: number;
    typeOfDiscount: number;
}

interface InvoiceItems {
    tuitions: Set<Tuition>;
    articles: Set<Article>;
}

const invoiceItems: InvoiceItems = {
    tuitions: new Set<Tuition>(),
    articles: new Set<Article>()
}

const initialState: any = {
    refresh: false,
    showModal: false,
    modalContent: 0,
    studentData: {},
    selectedInvoice: null,
    refundItems: new Set<number>(),
    totalToPaid: 0,
    isPartialPayment: false,
    isCancelPayment: false,
    loadingInvoice: false,
    loadingInvoicePdf: false,
    selectedPayment: {},
    error: null,
    invoiceItems
}

const http = HttpManager.getInstance();
export const handleSelectedInvoice = createAsyncThunk(
    "payments/handleSelectedInvoice",
    async ({ sessionToken, campusId, studentId, invoiceId }: any, { rejectWithValue }) => {
        try {
            const response = await http.invoiceService.getInvoiceById(
                sessionToken,
                campusId,
                studentId,
                invoiceId
            );
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const handleInvoicePdf = createAsyncThunk(
    "invoices/handleInvoicePdf",
    async ({ sessionToken, campusId, studentId, invoiceId }: any, { rejectWithValue }) => {
        try {
            const response = await http.fileService.getInvoicePDF(sessionToken, campusId, studentId, invoiceId);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice_${invoiceId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return invoiceId;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const invoices = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        setRefresh: (state, action) => {
            state.refresh = action.payload;
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload;
        },
        setModalContent: (state, action) => {
            state.modalContent = action.payload;
        },
        setStudentData: (state, action) => {
            state.studentData = action.payload;
        },
        setSelectedInvoice: (state, action) => {
            state.selectedInvoice = action.payload;
        },
        setSelectedPayment: (state, action) => {
            state.selectedPayment = action.payload;
        },
        setIsNewInvoice: (state, action) => {
            state.isNewInvoice = action.payload;
        },
        setTotalToPaid: (state, action) => {
            state.totalToPaid = action.payload;
        },
        setIsPartialPayment: (state, action) => {
            state.isPartialPayment = action.payload;
        },
        setIsCancelPayment: (state, action) => {
            state.isCancelPayment = action.payload;
        },
        setRefundItems: (state, action: PayloadAction<any>) => {
            state.refundItems = action.payload;
        },
        handleRefundItemsSelection: (state, action: PayloadAction<any>) => {
            const item = action.payload;
            if (state.refundItems.has(item.id)) {
                state.refundItems.delete(item.id);
            } else {
                state.refundItems.add(item.id);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(handleSelectedInvoice.pending, (state: any) => {
                state.loadingInvoice = true;
            })
            .addCase(handleSelectedInvoice.fulfilled, (state: any) => {
                state.loadingInvoice = false;
            })
            .addCase(handleSelectedInvoice.rejected, (state: any, action: any) => {
                state.loadingInvoice = false;
                state.error = action.payload;
            })
            .addCase(handleInvoicePdf.pending, (state: any) => {
                state.loadingInvoicePdf = true;
            })
            .addCase(handleInvoicePdf.fulfilled, (state: any) => {
                state.loadingInvoicePdf = false;
            })
            .addCase(handleInvoicePdf.rejected, (state: any, action: any) => {
                state.loadingInvoicePdf = false;
                state.error = action.payload;
            });
    }
});


export const {
    setShowModal,
    setRefresh,
    setModalContent,
    setStudentData,
    setSelectedInvoice,
    setRefundItems,
    handleRefundItemsSelection,
    setSelectedPayment,
    setTotalToPaid,
    setIsPartialPayment,
    setIsCancelPayment,
    setIsNewInvoice
} = invoices.actions;

export default invoices.reducer;
